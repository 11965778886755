export default {
  login (email: string, pass: string): void {
    // @ts-ignore
    this.$store.dispatch('loginUser', { email, pass })
  },

  logout () {
    localStorage.clear()
  },

  getToken () {
    if (localStorage.token) {
      return localStorage.token
    } else if (localStorage.guarantorToken) {
      return localStorage.guarantorToken
    }
  },

  loggedIn () {
    if (localStorage.guarantorToken) {
      return localStorage.guarantorToken
    } else {
      return !!localStorage.token
    }
  },
}
