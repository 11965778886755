<template>
  <div
    id="app"
    :class="{ 'lockAppScrolling': modalOpen }"
  >
    <alert v-if="$store.state.showAlert">
      {{ $store.state.alertText }}
    </alert>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import alert from '@/components/Alert.vue'
export default {
  name: 'App',

  components: {
    alert,
  },
  created () {
    // Sets the logged in user and fetches all active reservations
    this.$store.commit('setUser')
    this.$store.dispatch('loadProperties')
    // Sets the active property in cases where it is needed.
    if (localStorage.activeProperty !== null && localStorage.activeProperty !== "null" && localStorage.activeProperty != undefined) {
      const property = JSON.parse(localStorage.activeProperty)
      this.$store.dispatch('loadActiveProperty', property.id)
    }
  },
  computed: {
    modalOpen () {
      return this.$store.state.modalIsOpen
    },
  },
}
</script>
<style lang="scss">
@use 'sass:color';
@import './assets/scss/global-variables.scss';
#app {
  font-family: $main-font;
  color: #424242;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  font-size: 14px;
  width: 100%;
  overflow: hidden;
}
// this section below is what is breaking the messages view
*,
::before,
::after {
  box-sizing: border-box;
  max-width: none;
  padding: 0;
  margin: 0;
}
.lockAppScrolling {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.mobile_only {
  display: none;
  @include mobile {
    display: block;
  }
}
.desktop_only {
  @include mobile {
    display: none;
  }
}
// Styles taken from Vuetify
table {
  border-radius: 2px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
}
.input-group--text-field textarea:focus {
  outline: none;
}
textarea {
  overflow: auto;
  resize: vertical;
}
button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
}
h1 {
  color: #424242;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.04em;
  margin-bottom: 1rem;
}

h2 {
  color: #424242;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  margin-bottom: 1rem;
}

h3 {
  color: #424242;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: normal;
  margin-bottom: 1rem;
}

h4 {
  color: #424242;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: normal;
  margin-bottom: 1rem;
}

h5 {
  color: #424242;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: normal;
  margin-bottom: 1rem;
}

h6 {
  color: #424242;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-bottom: 1rem;
}
button,
input,
optgroup,
select,
textarea {
  font: inherit;
}
.btn {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #e0e0e0;
  border-radius: 2px;
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 6px;
  min-width: 88px;
  outline: 0;
  padding: 0 16px;
  text-decoration: none;
  position: relative;
  vertical-align: middle;
}

// End of Vuetify Styles

.tenant_nav_header_div {
  font-family: $main-font;
  overflow: auto;
  margin-bottom: 1rem;
  ul {
    text-align: left;
    border-bottom: 1px solid $rc-btn-gray;
    width: calc(100% - 6rem);
    margin: 0 auto;
    line-height: 3.5rem;
    @include mobile {
      width: calc(100% - 2rem);
    }
    li {
      text-align: left;
      font-size: 1.2rem;
      padding: 0 1.75rem;
      height: 3.5rem;
      display: inline-block;
      color: $rc-dark;
      font-weight: 500;
      line-height: 3.5rem;
      &:hover {
        cursor: pointer;
        border-bottom: 3px solid $rc-dark;
      }
    }
  }
  @include mobile {
    display: none;
    ul {
      line-height: 3rem;
      li {
        height: 3.5rem;
        line-height: 3.5rem;
        padding: 0 1.5rem;
      }
    }
  }
  .nav_btn {
    background-color: $rc-green;
    color: $white;
    cursor: pointer;
    border-radius: 2rem;
    height: 2.75rem;
    line-height: 2.75rem;
    float: right;
    margin: 0.7rem 0 0 1rem;
    font-size: 14px;
    font-weight: 600;
    &.disabled {
      opacity: 0.2;
    }
    &:hover {
      border: none;
    }
    @include mobile {
      height: 2.5rem;
      line-height: 2.5rem;
      margin: 0.6rem 0 0 1rem;
    }
  }
  .secondary_nav_btn {
    background-color: $rc-med-bg;
    border: 1px solid $rc-light;
    color: $rc-green;
    cursor: pointer;
    border-radius: 2rem;
    height: 2.75rem;
    line-height: calc(2.75rem - 2px);
    float: right;
    margin: 0.7rem 0 0 1rem;
    font-size: 14px;
    font-weight: 600;
    &.disabled {
      opacity: 0.2;
    }
    &:hover {
      border: 1px solid $rc-light;
    }
    @include mobile {
      display: none;
    }
  }
  .active_tab {
    border-bottom: 3px solid $rc-dark;
    font-weight: 600;
  }
}
.tenant_profile_table {
  border-collapse: collapse;
  border: 1px solid $rc-light;
  box-shadow: 0 3px 10px 0 rgba(34, 77, 97, 0.1);
  color: $rc-dark;
  font-family: $main-font;
  margin: 0 auto;
  width: calc(100% - 6rem);
  @include mobile {
    display: none;
  }
  tr {
    background-color: $rc-light-bg;
    &.hoverable {
      &:hover {
        background-color: $rc-med-bg;
        cursor: pointer;
      }
    }
  }
  th {
    background-color: color.adjust($rc-med-bg, $lightness: -3%);
    border: 1px solid $rc-light;
    font-size: 0.9rem;
    line-height: 1.8rem;
    padding: 0 0.2rem;
    text-align: center;
    .green_text {
      color: $rc-green;
    }
    .red_text {
      color: $red;
    }
    @include mobile {
      font-size: 0.9rem;
    }
  }
  td {
    border: 1px solid $rc-light;
    font-size: 0.95rem;
    font-weight: 500;
    height: 3rem;
    padding: 0.2rem;
    text-align: center;
    .green_text {
      color: $rc-green;
    }
    .red_text {
      color: $red;
    }
    .actions {
      margin: 0 auto;
      overflow: auto;
      padding: 0.1rem 0;
      width: 5rem;
    }
    .table_btn {
      border-radius: 2rem;
      cursor: pointer;
      display: grid;
      justify-content: center;
      align-content: center;
      float: left;
      height: 1.6rem;
      left: 0.5rem;
      margin: 0 0.2rem;
      position: relative;
      width: 1.6rem;
      i {
        cursor: pointer;
        font-size: 0.9rem;
      }
      &.btn_green {
        background-color: $rc-green;
        box-shadow: 0 2px 3px 0 rgba(34, 77, 97, 0.25);
        i {
          color: $white;
        }
      }
      &.btn_blue {
        background-color: $rc-boy;
        box-shadow: 0 2px 3px 0 rgba(34, 77, 97, 0.25);
        i {
          color: $white;
        }
      }
      &.btn_red {
        background-color: $red;
        box-shadow: 0 2px 3px 0 rgba(34, 77, 97, 0.25);
        i {
          color: $white;
        }
      }
    }
    .table_word_btn {
      background-color: transparent;
      border-radius: 2rem;
      box-shadow: 0 1px 2px 0 rgba(34, 77, 97, 0.1);
      cursor: pointer;
      display: inline-block;
      font-size: 0.9rem;
      font-weight: 600;
      line-height: 2rem;
      margin: 0 0.25rem;
      width: 5rem;
      &.wide {
        width: 7rem;
      }
      &.green_btn {
        border: 1px solid $rc-green;
        color: $rc-green;
      }
      &.blue_btn {
        border: 1px solid $rc-boy;
        color: $rc-boy;
      }
      &.red_btn {
        border: 1px solid $rc-red;
        color: $rc-red;
      }
      &.dark_btn {
        border: 1px solid $rc-dark;
        color: $rc-dark;
      }
    }
  }
  td.progress {
    i {
      color: $rc-light;
      margin: 0 0.2rem;
      &.step_complete {
        color: $rc-dark;
      }
    }
  }
}
.tenant_profile_CTA {
  text-align: center;
  @include mobile {
    padding: 0 1rem 2rem;
  }
  a {
    background-color: $rc-green;
    border-radius: 2rem;
    color: $white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.9rem 1.8rem;
    text-decoration: none;
  }
}
.rc_vertical_mobile_menu {
  display: none;
  border-top: 1px solid $rc-btn-gray;
  box-shadow: 0 3px 10px 0 rgba(34, 77, 97, 0.1);
  @include mobile {
    display: block;
  }
  .line {
    background-color: $rc-med-bg;
    border: 1px solid $rc-btn-gray;
    border-top: none;
    box-shadow: 0 1px 10px 0 rgba(34, 77, 97, 0.1);
    h3 {
      font-size: 1.1rem;
      font-weight: 600;
      line-height: 3rem;
      margin-bottom: 0;
      text-indent: 1.5rem;
      -webkit-transition: border 0.2s ease-out;
      transition: border 0.2s ease-out;
      &.active {
        border-left: 8px solid $rc-green;
      }
    }
  }
}
.mobile_table_list {
  display: none;
  @include mobile {
    display: block;
    .header {
      color: $rc-dark;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.5rem;
      margin: 0;
      padding-top: 1rem;
      text-align: center;
    }
    .item {
      margin-bottom: 0.5rem;
      position: relative;
      .icon {
        color: $rc-dark;
        font-size: 1.3rem;
        position: absolute;
        right: 1rem;
        top: 3.5rem;
        &.green_icon {
          color: $rc-green;
        }
      }
      p {
        font-size: 1.2rem;
        font-weight: 600;
        margin: 0;
        padding-top: 1.5rem;
        text-align: center;
      }
      ul {
        background-color: $rc-med-bg;
        border: 1px solid $rc-btn-gray;
        box-shadow: 0 5px 10px 0 rgba(34, 77, 97, 0.1);
        list-style-type: none;
        margin: 0 0.5rem;
        padding: 1rem 0;
        li {
          font-size: 1rem;
          text-align: center;
          .green_text {
            color: $rc-green;
            font-weight: 600;
          }
          .red_text {
            color: $red;
            font-weight: 600;
          }
          .mobile_green {
            background-color: $rc-green;
            border-radius: 2rem;
            box-shadow: 0 2px 3px 0 rgba(34, 77, 97, 0.25);
            color: $white;
            display: inline-block;
            font-size: 1.1rem;
            font-weight: 600;
            margin: 0 0.4rem;
            padding: 0.5rem 1rem;
            text-shadow: 0 0 1px $gray-dk;
          }
          .mobile_blue {
            background-color: $rc-boy;
            border-radius: 2rem;
            box-shadow: 0 2px 3px 0 rgba(34, 77, 97, 0.25);
            color: $white;
            display: inline-block;
            font-size: 1.1rem;
            font-weight: 600;
            margin: 0 0.4rem;
            padding: 0.5rem 1rem;
            text-shadow: 0 0 1px $gray-dk;
          }
          .mobile_red {
            background-color: $rc-red;
            border-radius: 2rem;
            box-shadow: 0 2px 3px 0 rgba(34, 77, 97, 0.25);
            color: $white;
            display: inline-block;
            font-size: 1.1rem;
            font-weight: 600;
            margin: 0 0.4rem;
            padding: 0.5rem 1rem;
            text-shadow: 0 0 1px $gray-dk;
          }
          .mobile_btn {
            color: $button-hover;
            text-decoration: underline;
          }
          .progress_bar {
            background-color: $rc-light-bg;
            border: 1px solid $rc-med;
            border-radius: 1.5rem;
            box-shadow: inset 0 2px 3px 0 rgba(34, 77, 97, 0.25);
            display: inline-block;
            height: 3rem;
            margin: 0.5rem 0;
            padding: 0 1rem;
            i {
              color: $rc-light;
              font-size: 1rem;
              line-height: 3rem;
              margin: 0 0.4rem;
              &.step_complete {
                color: $rc-dark;
              }
            }
          }
        }
        .button_item {
          height: 3rem;
        }
      }
    }
  }
}
.tenant_profile_no_results {
  font-size: 0.8rem;
  font-weight: 500;
  color: $rc-med;
  padding-top: 1rem;
  text-align: center;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  @include mobile {
    font-size: 0.8rem;
    line-height: 1.2rem;
    margin: 1rem;
  }
}
.rc_select {
  box-sizing: border-box;
  cursor: pointer;
  height: 2.5rem;
  margin: 0;
  outline: none;
  overflow: visible;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include mobile {
    height: 3rem;
  }
  li {
    background-color: $white;
    border: 1px solid $rc-light;
    border-top: none;
    box-sizing: border-box;
    color: $gray-dk;
    cursor: pointer;
    font-size: 0.95rem;
    font-weight: 600;
    line-height: 1.5rem;
    list-style-type: none;
    padding: 0.5rem 1rem;
    position: relative;
    text-transform: capitalize;
    width: 100%;
    z-index: 5;
    transition: border 0.2s ease-out;
    -webkit-transition: border 0.2s ease-out;
    @include mobile {
      font-size: 1rem;
      line-height: 2rem;
    }
    &:hover {
      filter: brightness(99%);
    }
    &.active_keyboard {
      border-left: 4px solid $rc-green;
    }
    .select_arrow {
      font-size: 0.75rem;
      position: absolute;
      right: 1rem;
      top: 0.85rem;
      transition: transform 0.2s ease-out;
      -webkit-transition: transform 0.2s ease-out;
      @include mobile {
        top: 1.15rem;
        right: 1.2rem;
      }
    }
    .rotate_half {
      transform: rotateZ(180deg);
    }
    .fa-check {
      color: $rc-green;
      position: absolute;
      right: 1rem;
      line-height: 2.5rem;
      top: 0;
    }
  }
  .main_button {
    border: 1px solid $rc-light;
    border-top-right-radius: 1.25rem;
    border-top-left-radius: 1.25rem;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    @include mobile {
      border-top-right-radius: 1.5rem;
      border-top-left-radius: 1.5rem;
    }
  }
  .rc_rounded {
    border-bottom-right-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
    @include mobile {
      border-bottom-right-radius: 1.5rem;
      border-bottom-left-radius: 1.5rem;
    }
  }
}
.rc_form_select {
  li {
    background-color: $rc-med-bg;
    font-size: 0.9rem;
    font-weight: 500;
  }
}
.rc_checkbox {
  // each checkbox requires a bg-color class, a shape class, and a dynamic active vs inactive
  background-color: $primary-color;
  box-shadow: 0 2px 3px 0 rgba(34, 77, 97, 0.25);
  cursor: pointer;
  text-align: center;
  height: 1.5rem;
  font-size: 1rem;
  width: 1.5rem;
  -webkit-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  &.active {
    &.bg-blue {
      background-color: $primary-color;
    }
    &.bg-green {
      background-color: $rc-green;
    }
  }
  &.inactive {
    background-color: color.adjust($gray, $lightness: 5%);
  }
  &.square {
    border-radius: 4px;
  }
  &.circle {
    border-radius: 0.75rem;
  }
  i {
    color: $white;
    font-size: 0.85rem;
    line-height: 1;
  }
}
.rc_toggle {
  .toggle_btn {
    border-radius: 0.65rem;
    color: $green;
    cursor: pointer;
    height: 0.8rem;
    margin: 0.2rem 0 0.2rem 2rem;
    position: relative;
    -webkit-transition: background-color 0.25s ease-out;
    transition: background-color 0.25s ease-out;
    width: 2.2rem;
    .slider {
      background-color: $gray-dk;
      border-radius: 50%;
      box-shadow: 0 2px 3px 0 rgba(34, 77, 97, 0.25); // color is $rc-dark. Shadowed as seen in mock-ups
      cursor: pointer;
      height: 1.2rem;
      position: absolute;
      top: -0.2rem;
      -webkit-transition: left 0.25s ease-out, background-color 0.25s ease-out;
      transition: left 0.25s ease-out, background-color 0.25s ease-out;
      width: 1.2rem;
    }
  }
  .active_toggle {
    background-color: rgba(20, 203, 136, 0.4);
    .slider {
      background-color: $rc-green;
      left: 1rem;
    }
  }
  .inactive_toggle {
    background-color: rgba(117, 152, 171, 0.5);
    .slider {
      background-color: $rc-med-bg;
      left: 0;
    }
  }
}
.rc_loader {
  z-index: 1000;
  font-family: $main-font;
  background-color: $white;
  text-align: center;
  &.sign_up-style {
    box-shadow: 0 5px 10px 0 rgba(34, 77, 97, 0.1);
    position: fixed;
    left: calc(50% - 300px - 1rem);
    top: 20vh;
    width: 600px;
    margin: 1rem;
    height: 380px;
    border: 1px solid $rc-btn-gray;
    transition: left 0.3s linear;
    -webkit-transition: left 0.3s linear;
    @include mobile {
      border: none;
      box-shadow: none;
      font-size: 2rem;
      height: auto;
      left: 0;
      margin: 0;
      top: 10rem;
      width: 100%;
    }
    &.sidebar_open {
      left: calc(50% - 300px - 10.5rem);
    }
  }
  &.modal-style {
    padding-bottom: 10rem;
    @include mobile {
      padding: 3rem 0 6rem;
    }
  }
  &.profile-style {
    border-radius: inherit;
    padding: 4rem 0;
    .loading_title {
      margin: 0 0 2rem;
    }
  }
  .loading_title {
    color: $gray;
    font-size: 2.7rem;
    font-weight: 700;
    line-height: 2.5rem;
    margin: 8rem 0 2rem;
    text-align: center;
    width: 100%;
    @include mobile {
      margin-top: 4rem;
    }
  }
  .loader {
    animation: rc_loader_animation 0.8s linear infinite;
    border: 5px solid $rc-light;
    border-right: 5px solid $primary-color;
    border-radius: 50%;
    height: 3rem;
    margin: 0 auto;
    width: 3rem;
  }
  @keyframes rc_loader_animation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
  .loading-circle {
    color: $primary-color;
  }
}
// modal stylings
.rc_modal_haze {
  animation: 0.25s ease-out 0s 1 hazeLoadIn;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  align-content: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  @include mobile {
    display: block;
    max-height: 100vh;
  }
}
.rc_modal {
  animation: 0.25s ease-out 0s 1 modalLoadIn;
  background-color: $rc-light-bg;
  border-radius: 0.3rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  font-family: $main-font;
  opacity: 1;
  transform: scale(1);
  z-index: 6;
  @include mobile {
    animation: 0.4s ease-out 0s 1 mobileModalLoadIn;
    border-radius: 0;
    box-shadow: none;
    height: 100%;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw !important;
  }
  .rc_modal_header {
    background-color: color.adjust($rc-med-bg, $lightness: -2%);
    border-bottom: 1px solid $rc-btn-gray;
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
    box-shadow: 0 5px 10px 0 rgba(34, 77, 97, 0.1);
    overflow: auto;
    @include mobile {
      border-radius: 0;
    }
    h2 {
      color: $header-dark-gray;
      float: left;
      font-family: $main-font;
      font-size: 1.3rem;
      font-weight: 600;
      letter-spacing: -0.1rem;
      line-height: 2.5rem;
      margin: 0;
      padding: 0 0.5rem 0 1rem;
      text-align: center;
      @include mobile {
        font-size: 1.4rem;
        line-height: 3.5rem;
        padding: 0 0.8rem;
      }
    }
    .close_modal_btn {
      border-radius: 50%;
      color: $gray-med;
      float: right;
      font-size: 1.2rem;
      margin: 0.2rem;
      text-align: center;
      height: 2.1rem;
      width: 2.1rem;
      transition: background-color 0.4s;
      i {
        line-height: 2.1rem;
        @include mobile {
          line-height: 3.1rem;
        }
      }
      @include mobile {
        font-size: 1.5rem;
        width: 3.1rem;
        height: 3.1rem;
        padding: 0 0.8rem;
      }
      &:hover {
        background-color: rgba(38, 38, 50, 0.1);
        cursor: pointer;
      }
    }
  }
  .rc_modal_subheader {
    color: $gray-med;
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    line-height: 1rem;
    margin: 1rem 0;
    text-align: center;
    text-transform: uppercase;
    @include mobile {
      font-size: 0.8rem;
      line-height: 1.2rem;
      margin: 1.5rem 1rem;
    }
  }
  .rc_modal_body {
    border: 1px solid $rc-light-bg;
    border-top: none;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    overflow-y: auto;
    padding: 0 1rem;
    max-height: 80vh;
    @include mobile {
      max-height: calc(100% - 3.5rem - 1px);
    }
    &::-webkit-scrollbar {
      //scroll bar styling
      width: 6px;
      background: transparent;
      @include mobile {
        width: 5px;
      }
    }
    &::-webkit-scrollbar-track-piece {
      background-color: $gray;
      -webkit-border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb:vertical {
      background-color: $rc-med;
      -webkit-border-radius: 6px;
    }
    p {
      color: $body-text-gray;
      font-family: $main-font;
      line-height: 1.5rem;
      font-size: 0.9rem;
      font-weight: 500;
      word-spacing: 1px;
      margin: 1.2rem 0 0 0;
      text-align: left;
      width: 100%;
      @include mobile {
        font-size: 0.95rem;
        line-height: 1.4rem;
      }
    }
    input {
      background-color: $rc-med-bg;
      border: 1px solid $gray-border;
      border-radius: 1.25rem;
      color: $gray-dk;
      display: block;
      font-size: 1rem;
      font-weight: 600;
      height: 2.5rem;
      outline: none;
      text-indent: 1rem;
      width: 100%;
      transition: border-color 0.1s ease-out;
      -webkit-transition: border-color 0.1s ease-out;
      &:focus {
        border-color: $gray;
      }
      @include mobile {
        border-radius: 1.5rem;
        height: 3rem;
        text-indent: 1.3rem;
      }
    }
    .rc_select {
      li {
        background-color: $rc-med-bg;
      }
    }
    .rc_modal_btn_div {
      clear: both;
      padding: 2rem 0;
      text-align: center;
      .btn {
        border-radius: 2rem;
        color: $white;
        font-size: 0.95rem;
        font-weight: 600;
        padding: 0.7rem 2rem;
        cursor: pointer;
        text-align: center;
        transition: opacity 0.3s ease-out;
        -webkit-transition: opacity 0.3s ease-out;
        &.blue_btn {
          background-color: $rc-boy;
        }
        &.green_btn {
          background-color: $rc-green;
        }
        &.red_btn {
          background-color: $rc-red;
        }
        &.dark_btn {
          background-color: $rc-dark;
        }
        &.not_allowed {
          cursor: not-allowed;
          opacity: 0.3;
        }
      }
    }
  }
}
@keyframes hazeLoadIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes modalLoadIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes mobileModalLoadIn {
  0% {
    opacity: 0;
    top: 100vh;
    transform: scale(0.6);
  }
  100% {
    opacity: 1;
    top: 0;
    transform: scale(1);
  }
}
</style>
