import axios from 'axios'
import store from './store'
import { API } from '../api/endpoints'
import { navigateTo, ROUTES } from '../router/navigation'

// Should only need to be run once in the created hook of App.vue
export const loadProperties = ({ commit }: any) => {
  axios
    .get(API.PROPERTIES.BASE)
    .then(function (response: any) {
      commit('setProperties', { properties: response.data.filter((property: any) => property.status === 'RUNNING') })
    })
    .catch(e => {
      console.log(e)
    })
}
// Cancels a reservation and updates user reservations
export const cancelReservation = ({ commit }: any, reservation: any) => {
  axios
    .post(API.SALES_FUNNELS.CANCEL(reservation.id))
    .then(response => {
      store.dispatch('loadUserReservations', store.state.user.id)
    })
    .catch(e => {
      var errorText = 'Your reservation could not be deleted. Please try again later.'
      var alertType = 'warning'
      var alertInfo = { msg: errorText, type: alertType }
      commit('toggleAlert', alertInfo)
    })
}
// Fetches charges due at signing and sets a total amount due.
export const getDueNowCharges = ({ commit }: any, reservationID: any) => {
  axios
    .get(API.SALES_FUNNELS.PAY_FIRST_NOW.WITH_SIDEBAR(reservationID))
    .then(response => {
      var charges = ''
      var totalDue = 0
      charges = response.data.pay_first_now
      for (var i = 0; i < charges.length; i++) {
        totalDue += parseInt((charges[i] as any).Amount, 10)
      }
      commit('setDueNowTotal', totalDue)
      commit('setDueNowCharges', charges)
    })
    .catch(e => {
      console.log(e)
    })
}
// Loads the active property and associated property settings, used primarily
// in the sign-up process and for disabling payments on the profile.
export const loadActiveProperty = ({ commit }: any, propertyID: any) => {
  return new Promise<void>((resolve, reject) => {
    axios.get(API.PROPERTIES_SETTINGS(propertyID)).then(
      response => {
        localStorage.activeProperty = JSON.stringify(response.data)
        store.dispatch('setActiveProperty', response.data)
        resolve()
      },
      err => {
        console.log(err)
        reject(new Error())
      },
    )
  })
}
// Loads all tenant-visible agreement periods
export const loadAgreementPeriods = ({ commit }: any, property: any) => {
  axios
    .get(API.AGREEMENT_PERIODS, { params: { property: property, visible: true } })
    .then(response => {
      commit('setAgreementPeriods', { periods: response.data })
    })
    .catch(e => {
      console.log(e)
    })
}
// Logs in the user, determines their user type, saves a token.
export const loginUser = ({ commit }: any, credentials: any) => {
  axios.defaults.headers.common.authorization = ''
  if (localStorage.getItem('organizationID')) {
    axios.defaults.headers.common['org-id'] = localStorage.organizationID
  }
  localStorage.clear()
  axios
    .post(API.TOKEN_AUTH, credentials)
    .then(response => {
      // Set local storage and redirect to manager app if user is a manager
      if (response.data.user.roles[0] === 'manager') {
        localStorage.mgrOrganizationID = response.data.organization
        localStorage.mgrToken = response.data.token
        localStorage.mgrUser = JSON.stringify(response.data.user)
        window.location.href = `${process.env.VUE_APP_ROOMCHOICE_APP_URL}/rc-manager/alerts/?mgrUser=${localStorage.mgrUser}&mgrToken=${localStorage.mgrToken}&mgrOrganizationID=${localStorage.mgrOrganizationID}`
      } else if (response.data.user.roles[0] === 'maintenance') {
        // Set local storage and redirect to manager app if user is a manager
        localStorage.mgrOrganizationID = response.data.organization
        localStorage.mgrToken = response.data.token
        localStorage.mgrUser = JSON.stringify(response.data.user)
        window.location.href = `${process.env.VUE_APP_ROOMCHOICE_APP_URL}/rc-manager/maintenance/tickets/?mgrUser=${localStorage.mgrUser}&mgrToken=${localStorage.mgrToken}&mgrOrganizationID=${localStorage.mgrOrganizationID}`
      } else {
        // Set local storage and login if user is a tenant
        localStorage.organizationID = response.data.organization
        localStorage.token = response.data.token
        localStorage.user = JSON.stringify(response.data.user) as string
        (window as any).axios.defaults.headers.common.authorization = 'Token ' + localStorage.token;
        (window as any).axios.defaults.headers.common['org-id'] = localStorage.organizationID
        commit('setActiveOrganizationId', {organizationId: localStorage.organizationID})
        commit('setUser', { user: response.data.user })
        window.location.href = `${process.env.VUE_APP_ROOMCHOICE_APP_URL}/tenant-profile/user-reservations/reservations/?user=${localStorage.user}&token=${localStorage.token}&organizationID=${localStorage.organizationID}`
      }
      store.commit('toggleModal', 'close')
    })
    .catch(e => {
      if (e.response.data.detail) {
        var errorText = e.response.data.detail
        var alertType = 'warning'
        var alertInfo = { msg: errorText, type: alertType }
        commit('toggleAlert', alertInfo)
      } else if (e.response.data.non_field_errors[0]) {
        errorText = e.response.data.non_field_errors[0]
        alertType = 'warning'
        alertInfo = { msg: errorText, type: alertType }
        commit('toggleAlert', alertInfo)
      }
    })
}
// TODO: replace the login with /me info shown here if practical.
export const setTenant = ({ commit }: any, tenantInfo: any) => {
  localStorage.user = JSON.stringify(tenantInfo)
  commit('setUser')
}

export const setUserLite = ({ commit }: any, theseParameters: any) => {
  var thisUser = theseParameters.user
  var thisReservationId = theseParameters.reservationId
  commit('setUser', { user: thisUser })

  axios
    .get(API.SALES_FUNNELS.FETCH(thisReservationId))
    .then(response => {
      var reservation = response.data
      store.dispatch('loadActiveReservation', reservation)
      store.dispatch('loadActiveProperty', reservation.property)
      if (reservation.is_quiz_passed || store.state.user.is_quiz_skipped) {
        if (!reservation.is_quiz_passed) {
          store.dispatch('updateQuizRequest', reservation.id)
        }
        if (reservation.is_charges_confirmed) {
          if (reservation.is_application_completed) {
            if (reservation.is_tenant_signed) {
              if (reservation.is_payment_completed) {
                navigateTo(ROUTES.TENANT_PROFILE.RESERVATIONS.RESERVATIONS)
              } else {
                navigateTo(ROUTES.SIGN_UP.MAKE_PAYMENT, {
                  reservationID: reservation.id,
                  tenantID: store.state.user.id,
                })
              }
            } else {
              navigateTo(
                ROUTES.SIGN_UP.CONTRACT, {
                  reservationID: reservation.id,
                  tenantID: store.state.user.id,
                })
            }
          } else {
            navigateTo(
              ROUTES.SIGN_UP.APPLICATION, {
                reservationID: reservation.id,
                tenantID: store.state.user.id,
              })
          }
        } else {
          navigateTo(ROUTES.SIGN_UP.BED_SELECTION, {
            destination: false,
            inviteFriend: false,
            reservationID: reservation.id,
            tenantID: store.state.user.id,
          })
        }
      } else {
        navigateTo(ROUTES.SIGN_UP.QUIZ_REQUEST, { reservationID: reservation.id, tenantID: store.state.user.id })
      }
    })
    .catch(e => {
      console.log(e)
    })
}

// TODO This is run in the created life cycle hook in App.vue by the setUser mutation and shouldn't need to be
// run elsewhere unless an update to reservations has been made.
export const loadUserReservations = ({ commit }: any, userID: any) => {
  if (localStorage.activePropertyId) {
    axios.get(API.VUE_USER_SALES_FUNNELS.GET_SALES_FUNNELS(userID, { property: localStorage.activePropertyId, showFriendsTab: false })).then(response => {
      const reservations = response.data.sort(function (a: any, b: any) {
        var sortA = a.start_date
        var sortB = b.start_date
        if (sortA < sortB) {
          return -1
        }
        if (sortA > sortB) {
          return 1
        }
        return 0
      })

      commit('setReservations', { userReservations: reservations })
      return reservations
    })
  }
}

// Ensures we don't lose the in-process reservation during the sign-up process.
export const loadActiveReservation = ({ commit }: any, reservation: any) => {
  localStorage.activeReservation = JSON.stringify(reservation)
  commit('setActiveReservation')
}
// Generates a new reservation, includes handling for the custom "same bed renewal" feature.
export const createNewReservation = ({ commit }: any, reservationInfo: any) => {
  var payload: any = {
    property: reservationInfo.property,
    agreement_period: reservationInfo.agreement_period,
  }
  if (reservationInfo.same_bed_renew) {
    payload.same_bed_renew = true
  }
  axios
    .post(API.USERS.NEW_RESERVATION(reservationInfo.user), payload)
    .then(response => {
      var reservation = response.data
      store.dispatch('resumeReservation', reservation)
    })
    .catch(e => {
      var errorText = e.response.data
      var alertType = 'warning'
      var alertInfo = { msg: errorText, type: alertType }
      commit('toggleAlert', alertInfo)
    })
}
// Re-enters the sign-up flow according to current progress step.
export const resumeReservation = ({ commit }: any, reservation: any) => {
  store.dispatch('loadActiveReservation', reservation)
  store.dispatch('loadActiveProperty', reservation.property).then(() => {
    if (reservation.current_step === 'begin_renew') {
      navigateTo(ROUTES.SIGN_UP.RENEW, { reservationID: reservation.id, tenantID: store.state.user.id })
    } else if (reservation.current_step === 'bed_selection') {
      navigateTo(ROUTES.SIGN_UP.BED_SELECTION, {
        destination: false,
        inviteFriend: false,
        reservationID: reservation.id,
        tenantID: store.state.user.id,
      })
    } else if (reservation.current_step === 'quiz_select') {
      navigateTo(ROUTES.SIGN_UP.QUIZ_REQUEST, { reservationID: reservation.id, tenantID: store.state.user.id })
    } else if (reservation.current_step === 'application') {
      navigateTo(ROUTES.SIGN_UP.APPLICATION, { reservationID: reservation.id, tenantID: store.state.user.id })
    } else if (reservation.current_step === 'contract') {
      navigateTo(ROUTES.SIGN_UP.CONTRACT, { reservationID: reservation.id, tenantID: store.state.user.id })
    } else if (reservation.current_step === 'make_payment') {
      navigateTo(ROUTES.SIGN_UP.MAKE_PAYMENT, { reservationID: reservation.id, tenantID: store.state.user.id })
    } else if (reservation.current_step === 'complete') {
      navigateTo(ROUTES.TENANT_PROFILE.RESERVATIONS.RESERVATIONS)
    }
  })
}

export const loadAutopayInfo = ({ commit }: any, userID: any) => {
  axios
    .get(API.PAYMENT_SCHEDULES.BASE, { params: { student: userID } })
    .then(response => {
      var autopayInfo = response.data
      commit('setAutopayInfo', autopayInfo)
    })
    .catch(e => {
      console.log(e)
    })
}

export const togglePublicProfile = ({ commit }: any, userID: any) => {
  axios
    .post(API.USERS.SHOW_PUBLIC_PROFILE(userID, !store.state.user.show_public_profile))
    .then(response => {
      (this as any).$store.state.user.show_public_profile = !(this as any).$store.state.user.show_public_profile
    })
    .catch(e => {
      console.log(e)
    })
}

export const sendMessage = ({ commit }: any, messageInfo: any) => {
  return new Promise((resolve, reject) => {
    axios.post(API.MESSAGES.TO_PROPERTY, messageInfo).then(
      response => {
        resolve(response)
      },
      error => {
        var errorText = 'Your message could not be sent. Please try again later.'
        var alertType = 'warning'
        var alertInfo = { msg: errorText, type: alertType }
        commit('toggleAlert', alertInfo)
        reject(error)
      },
    )
  })
}

export const loadUserCards = ({ commit }: any, userID: any) => {
  axios
    .get(API.USERS.GET_CARDS(userID))
    .then(response => {
      for (var i = response.data.length - 1; i >= 0; i--) {
        if (response.data[i].brand === 'Visa') {
          response.data[i].img = '../../visa.png'
        } else if (response.data[i].brand === 'MasterCard') {
          response.data[i].img = '../../mc.png'
        } else if (response.data[i].brand === 'American Express') {
          response.data[i].img = '../../amex.png'
        } else if (response.data[i].brand === 'Discover') {
          response.data[i].img = '../../discover.png'
        } else {
          response.data[i].img = ''
        }
      }
      var cards = response.data
      commit('setCards', cards)
    })
    .catch(e => {
      console.log(e)
    })
}

export const loadUserAccounts = ({ commit }: any, userID: any) => {
  axios
    .get(API.USERS.GET_ACCOUNTS(userID))
    .then(response => {
      var accounts = response.data
      commit('setAccounts', accounts)
    })
    .catch(e => {
      console.log(e)
    })
}

export const updateQuizRequest = ({ commit }: any, reservationID: any) => {
  axios.post(API.VUE_SALES_FUNNEL.UPDATE_QUIZ(reservationID)).then(response => {
    commit('setQuizRequest')
  })
}

export const updateApplication = ({ commit }: any, payload: any) => {
  axios
    .post(API.SALES_FUNNELS.FILL_FORM(payload.reservationID), payload.model)
    .then(response => {
      var errorText = 'Your application was saved successfully'
      var alertType = 'success'
      var alertInfo = { msg: errorText, type: alertType }
      store.commit('toggleAlert', alertInfo)
      commit('setApplication')
    })
}

export const updatePayment = ({ commit }: any, reservationID: any) => {
  axios.post(API.VUE_SALES_FUNNEL.UPDATE_PAYMENT(reservationID)).then(response => {
    commit('setPayment')
  })
}

export const setActiveProperty = ({ commit }: any, property: any) => {
  localStorage.activeProperty = JSON.stringify(property)
  localStorage.activePropertyId = property.id
  localStorage.organizationID = property.organization
  commit('setActiveOrganizationId', {organizationId: property.organization})
  commit('setActiveProperty', { property: property })
}
