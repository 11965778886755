import { createStore } from "vuex";
import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

export default createStore({
  actions,
  getters,
  mutations,
  state: {
    activeProperty: {} as any,
    activeOrganization: {} as any,
    propertySite: {} as any,
    periods: {} as any,
    user: {} as any,
    guarantorUser: {} as any,
    userReservations: '',
    properties: {} as any,
    activeReservation: {} as any,
    showSidebar: false,
    modalIsOpen: false,
    loading: false,
    friendBedSelection: false,
    activeFriend: {} as any,
    autopayInfo: '',
    invitations: '',
    userBalance: '',
    inboxMessages: '',
    sentMessages: '',
    userCards: '',
    userAccounts: '',
    dueNowCharges: '',
    dueNowTotal: '',
    activeAssignment: '',
    //
    showAlert: false,
    alertText: '',
    alertType: '',
    //
    showInfoBanner: false,
    infoBannerWhatToShow: '',
    //
    friendInfo: '',
    reloadApplication: false,
    multiproperty: false,
  },
})
