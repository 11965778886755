<template>
  <div
    class="Alert"
    :class="'Alert--' + $store.state.alertType"
  >
    <div class="alertbox">
      <p><slot /></p>
      <i
        class="fa fa-times"
        @click="$store.state.showAlert = false"
      />
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    setTimeout(this.closeAlert, 8000)
  },
  methods: {
    closeAlert () {
      this.$store.state.showAlert = false
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/scss/global-variables.scss';

.Alert {
  color: $rc-dark;
  background-color: $white;
  border: 1px solid $rc-btn-gray;
  padding: 0.5rem;
  font-family: $main-font;
  position: fixed;
  z-index: 9999;
  top: 3rem;
  right: 0.5rem;
  border-radius: 0.2rem;
  box-shadow: 0 5px 15px 0 rgba(24, 55, 69, 0.35), 0 5px 25px 0 rgba(24, 55, 69, 0.25);
  box-sizing: border-box;
  @include mobile {
    border-radius: 0;
    font-size: 1rem;
    padding: 1rem;
    top: 0.5rem;
    right: 0.5rem;
    width: calc(100% - 1rem);
  }
  .alertbox {
    p {
      color: $rc-dark;
      float: left;
      font-size: 15px;
      font-weight: 500;
      line-height: 2rem;
      margin: 0;
      @include mobile {
        line-height: 1.5rem;
        margin: 0;
        width: 85%;
      }
    }
    i {
      float: right;
      font-size: 1rem;
      line-height: 2rem;
      padding-left: 1rem;
      width: 2rem;
      &:hover {
        color: $red;
        cursor: pointer;
      }
    }
  }
}

.Alert--info {
  border-left: 6px solid $rc-med;
}

.Alert--success {
  border-left: 6px solid $rc-green;
}

.Alert--warning {
  border-left: 6px solid $warning-alert;
}
</style>
