import axios from 'axios'
import store from './store'
import { API } from '../api/endpoints'

export default {
  // Common Mutations //
  setProperties: (state: any, { properties }: any) => {
    state.properties = properties
    if (properties && properties.length > 1) {
      state.multiproperty = true
    }
  },
  setActiveOrganizationId: (state: any, { organizationId }: any) => {
    if (organizationId) {
      axios
        .get(API.ORGANIZATIONS.SINGLE(organizationId))
        .then((response: { data: any }) => {
          var organization = response.data;
          state.activeOrganization = organization;
      })
    } else if (localStorage.activeOrganization) {
      state.activeOrganizationId = localStorage.organizationId
    } else {
      console.log('Error setting Organization')
    }
  },
  setActiveProperty: (state: any, { settings }: any) => {
    if (settings) {
      state.activeProperty = settings
    } else if (localStorage.activeProperty) {
      state.activeProperty = JSON.parse(localStorage.activeProperty)
    } else {
      console.log('Error setting Property')
    }
  },
  setSideBarBed: (state: any, bedInfo: any) => {
    state.activeReservation.bed = bedInfo
  },
  setPropertySite: (state: any, { site }: any) => {
    state.propertySite = site
  },
  setAgreementPeriods: (state: any, { periods }: any) => {
    state.periods = periods
    state.loading = false
  },
  setUser: (state: any) => {
    if (localStorage.user) {
      state.user = JSON.parse(localStorage.user)
      store.dispatch('loadUserReservations', state.user.id)
    } else if (localStorage.guarantorUser) {
      state.guarantorUser = JSON.parse(localStorage.guarantorUser)
    } else {
      console.log('Error setting User')
    }
    FS.identify(state.user.id + window.location.hostname, {
      displayName: state.user.name,
      email: state.user.email,
    })
  },
  setInboxMessages: (state: any, messages: any) => {
    state.inboxMessages = messages.sort(function (a: any, b: any) {
      var sortA = a.sent_date
      var sortB = b.sent_date
      if (sortA > sortB) {
        return -1
      }
      if (sortA < sortB) {
        return 1
      }
      return 0
    })
  },
  setSentMessages: (state: any, messages: any) => {
    state.sentMessages = messages.sort(function (a: any, b: any) {
      var sortA = a.date_created
      var sortB = b.date_created
      if (sortA > sortB) {
        return -1
      }
      if (sortA < sortB) {
        return 1
      }
      return 0
    })
  },
  startLoad: (state: any) => {
    state.loading = true
  },
  stopLoad: (state: any) => {
    state.loading = false
  },
  startFriendBedSelection: (state: any, friendInfo: any) => {
    state.friendInfo = friendInfo
    localStorage.friendInfo = JSON.stringify(friendInfo)
    state.friendBedSelection = true
  },
  stopFriendBedSelection: (state: any) => {
    state.friendBedSelection = false
  },
  toggleAlert (state: any, alertInfo: any) {
    state.alertText = alertInfo.msg
    state.alertType = alertInfo.type
    state.showAlert = true
  },
  toggleInfoBanner (state: any, bannerInfo: any) {
    state.infoBannerWhatToShow = bannerInfo
    state.showInfoBanner = true
  },

  // Tenant Mutations //

  setReservations: (state: any, { userReservations }: any) => {
    state.userReservations = userReservations
  },
  setInvitations: (state: any, invitations: any) => {
    state.invitations = invitations
  },
  setUserBalance: (state: any, balance: any) => {
    state.userBalance = balance
  },
  setActiveReservation: (state: any) => {
    if (localStorage.activeReservation) {
      state.dueNowCharges = ''
      state.activeReservation = JSON.parse(localStorage.activeReservation)
      if (state.activeReservation.bed) {
        store.dispatch('getDueNowCharges', state.activeReservation.id)
      }
    } else {
      console.log('Error setting Active Reservation')
    }
  },
  clearActiveReservation: (state: any) => {
    state.activeReservation = {}
    localStorage.removeItem('activeReservation')
    console.log('Reservation cleared')
  },
  setDueNowTotal: (state: any, total: any) => {
    state.dueNowTotal = total
  },
  setAutopayInfo: (state: any, autopayInfo: any) => {
    state.autopayInfo = autopayInfo
  },
  setQuizRequest: (state: any) => {
    state.activeReservation.is_quiz_passed = true
    state.user.quiz_done = true
  },
  setCharges: (state: any, chargesDue: any) => {
    state.chargesDue = chargesDue
    state.activeReservation.is_charges_confirmed = true
  },
  setCards: (state: any, cards: any) => {
    state.userCards = cards
  },
  setAccounts: (state: any, accounts: any) => {
    state.userAccounts = accounts
  },
  setApplication: (state: any) => {
    state.activeReservation.is_application_completed = true
    state.reloadApplication = true
  },
  reloadApplication: (state: any) => {
    state.reloadApplication = false
  },
  setContract: (state: any) => {
    state.activeReservation.is_tenant_signed = true
  },
  setPayment: (state: any) => {
    state.activeReservation.is_payment_completed = true
  },
  setDueNowCharges: (state: any, charges: any) => {
    state.dueNowCharges = charges
  },
  toggleSidebar: (state: any) => {
    state.showSidebar = !state.showSidebar
  },
  toggleModal: (state: any, command: any) => {
    if (command === 'open') {
      state.modalIsOpen = true
    } else if (command === 'close') {
      state.modalIsOpen = false
    }
  },
  setActiveBed: (state: any, bedID: any) => {
    state.activeBed = bedID
  },
  logout: (state: any) => {
    localStorage.clear()
    // TODO Move to instance.
    ;(window as any).axios.defaults.headers.common.authorization = ''
    state.activeProperty = ''
    state.activeReservation = ''
    state.user = ''
    state.guarantorUser = ''
    state.userReservations = ''
    window.location.href = state.activeOrganization.default_domain
  },
}
