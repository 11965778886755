import AppRouter from './router'

export function navigateTo (route: string, params?: any): void {
  AppRouter.push({ name: route, params: params })
}

export const ROUTES = {
  LOGIN: 'Login',
  LOGOUT: '/logout',
  HOME: 'Home',
  ORGANIZATION_HOME: 'OrgHome',
  PROPERTY_HOME: 'PropHome',
  PORTFOLIO: 'Portfolio',

  SIGN_UP: {
    APPLICATION: 'Application',
    QUIZ_REQUEST: 'Quiz Request',
    PERSONALITY_QUIZ: 'PersonalityQuiz',
    CONTRACT: 'Contract',
    BED_SELECTION: 'Bed Selection',
    ADD_PAYMENT_METHOD: 'Add Payment Method',
    MAKE_PAYMENT: 'Make Payment',
    FEEDBACK: 'Feedback',
    RENEW: 'Renew',
    ADD_FRIEND: 'Add Friend Request',
    CHANGE_PERIOD: 'changePeriodModal',
    CHANGE_PROPERTY: 'changePropertyModal',
  },

  TENANT_PROFILE: {
    HOME: 'Tenant Profile',
    MESSAGES: 'Tenant Messages',
    MAINTENANCE: 'Tenant Maintenance',
    SETTINGS: 'Tenant Settings',
    MY_INFO: {
      HOME: 'My Info',
      PERSONAL_INFO: 'Personal Info',
      APPLICATION_INFO: 'Application Info',
      DOCUMENTS: 'Documents',
    },
    RESERVATIONS: {
      RESERVATIONS: 'Reservations',
      INVITATIONS: 'Invitations',
    },
    FRIENDS: {
      HOME: 'Tenant Friends',
      RELATED_USERS: 'Related Users',
    },
    FINANCE_CENTER: {
      HOME: 'Finance Center',
      AUTO_PAY: 'Autopay',
      PAYMENT_METHODS: 'Payment Methods',
      MAKE_PAYMENT: 'Make A Payment',
      PAYMENT_HISTORY: 'Payment History',
    },
  },

  ONLY_FROM_BACKEND: {
    SIGN_CONTRACT_GUARANTOR: 'Guarantor',
    CREATE_ACCOUNT: {
      CONFIRM_EMAIL_OLD_PROPS_STYLE: 'Confirm Email Old',
      CONFIRM_EMAIL: 'Confirm Email',
      SET_PASSWORD: 'Set Password',
      DECLINE_INVITATION: 'Decline Invitation',
      CLAIM_ASSIGNMENT: 'Claim Pending',
      CLAIM_INVITATION: 'Claim Invitation',
    },
    REMINDER: 'Reminder',
  },
}
