import { createWebHistory, createRouter, RouterScrollBehavior } from "vue-router";
import auth from '../auth'


function requireAuth (to: any, from: any, next: any) {
  if (!auth.loggedIn()) {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    })
  } else {
    next()
  }
}

export default createRouter({
  history: createWebHistory(),
  scrollBehavior: <RouterScrollBehavior> function (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  } ,
  routes: [{
    path: '/',
    component: () => import('../views/GoogleAnalyticsWrapper.vue'),
    children: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/home',
      name: 'OrgHome',
      component: () => import('@/views/OrgHome.vue'),
    },
    {
      path: '/home/:propid',
      name: 'PropHome',
      component: () => import('@/views/PropHome.vue'),
    },
    {
      path: '/portfolio',
      name: 'Portfolio',
      component: () => import('@/views/Portfolio.vue'),
    },
    {
      path: '/sign-contract',
      name: 'Guarantor',
      component: () => import('@/views/Guarantor.vue'),
      props: route => ({
        invite: route.query.invite,
        funnel: route.query.funnel,
      }),
    },
    {
      path: '/create/login/',
      name: 'Confirm Email Old',
      component: () => import('@/views/ConfirmEmail.vue'),
      props: route => ({
        invite: route.query.confirm,
      }),
    },
    {
      path: '/create/login/:confirm',
      name: 'Confirm Email',
      component: () => import('@/views/ConfirmEmail.vue'),
    },
    {
      path: '/create/set-password/:tokenId',
      name: 'Set Password',
      component: () => import('@/components/SetPassword.vue'),
    },
    {
      path: '/create/decline-pending-assignment/:token',
      name: 'Decline Pending',
      component: () => import('@/components/DeclinePending.vue'),
    },
    {
      path: '/create/decline-invitation/:token',
      name: 'Decline Invitation',
      component: () => import('@/components/DeclinePending.vue'),
    },
    {
      path: '/create/claim-pending-assignment/:token',
      name: 'Claim Pending',
      component: () => import('@/components/ClaimPending.vue'),
    },
    {
      path: '/create/claim-invitation/:token',
      name: 'Claim Invitation',
      component: () => import('@/components/ClaimInvitation.vue'),
    },
    {
      path: '/reminder/:user_id/:token',
      name: 'Reminder',
      component: () => import('@/components/Reminder.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login.vue'),
    },
    {
      path: '/tenant-profile',
      name: 'Tenant Profile',
      component: () => import('@/views/tenant-profile/TenantProfile.vue'),
      beforeEnter: requireAuth,
      children: [
        {
          path: 'finance-center',
          name: 'Finance Center',
          component: () => import('@/views/tenant-profile/Finance/FinanceHome.vue'),
          beforeEnter: requireAuth,
          children: [
            {
              path: 'autopay',
              name: 'Autopay',
              component: () => import('@/views/tenant-profile/Finance/AutoPay.vue'),
              beforeEnter: requireAuth,
            },
            {
              path: 'pmt-methods',
              name: 'Payment Methods',
              component: () => import('@/views/tenant-profile/Finance/PmtMethods.vue'),
              beforeEnter: requireAuth,
            },
            {
              path: 'make-pmt',
              name: 'Make A Payment',
              component: () => import('@/views/tenant-profile/Finance/ProfilePmt.vue'),
              beforeEnter: requireAuth,
            },
            {
              path: 'pmt-history',
              name: 'Payment History',
              component: () => import('@/views/tenant-profile/Finance/ChargePmtHistory.vue'),
              beforeEnter: requireAuth,
            },
          ],
        },
        {
          path: 'my-info',
          name: 'My Info',
          component: () => import('@/views/tenant-profile/MyInfo/MyInfo.vue'),
          beforeEnter: requireAuth,
          children: [
            {
              path: 'personal-info',
              name: 'Personal Info',
              component: () => import('@/views/tenant-profile/MyInfo/PersonalInfo.vue'),
              beforeEnter: requireAuth,
            },
            {
              path: 'application-info',
              name: 'Application Info',
              component: () => import('@/views/tenant-profile/MyInfo/ApplicationInfo.vue'),
              beforeEnter: requireAuth,
            },
            {
              path: 'documents',
              name: 'Documents',
              component: () => import('@/views/tenant-profile/MyInfo/Documents.vue'),
              beforeEnter: requireAuth,
            },
          ],
        },
        {
          path: 'user-reservations',
          name: 'Tenant Reservations',
          component: () => import('@/views/tenant-profile/Reservations/TenantReservations.vue'),
          beforeEnter: requireAuth,
          children: [
            {
              path: 'reservations',
              name: 'Reservations',
              component: () => import('@/views/tenant-profile/Reservations/Reservations.vue'),
              beforeEnter: requireAuth,
            },
            {
              path: 'invitations',
              name: 'Invitations',
              component: () => import('@/views/tenant-profile/Reservations/Invitations.vue'),
              beforeEnter: requireAuth,
            },
          ],
        },
        {
          path: 'messages',
          name: 'Tenant Messages',
          component: () => import('@/views/tenant-profile/TenantMessages.vue'),
          beforeEnter: requireAuth,
        },
        {
          path: 'maintenance',
          name: 'Tenant Maintenance',
          component: () => import('@/views/tenant-profile/TenantMaintenance.vue'),
          beforeEnter: requireAuth,
        },
        {
          path: 'friends',
          name: 'Tenant Friends',
          component: () => import('@/views/tenant-profile/Friends/TenantFriends.vue'),
          beforeEnter: requireAuth,
          children: [
            {
              path: ':propertyID/:periodID',
              name: 'Related Users',
              component: () => import('@/views/tenant-profile/Friends/RelatedUsers.vue'),
              beforeEnter: requireAuth,
            },
          ],
        },
        {
          path: 'settings',
          name: 'Tenant Settings',
          component: () => import('@/views/tenant-profile/TenantSettings.vue'),
          beforeEnter: requireAuth,
        },
      ],
    },
    {
      path: '/sign-up/:tenantID/:reservationID',
      name: 'Sign Up',
      component: () => import('@/views/sign-up/SignUp.vue'),
      beforeEnter: requireAuth,
      children: [
        {
          path: 'application',
          name: 'Application',
          component: () => import('@/views/sign-up/Application.vue'),
          beforeEnter: requireAuth,
        },
        {
          path: 'quiz-request',
          name: 'Quiz Request',
          component: () => import('@/views/sign-up/QuizRequest.vue'),
          beforeEnter: requireAuth,
        },
        {
          path: 'personality-quiz',
          name: 'PersonalityQuiz',
          component: () => import('../components/PersonalityQuiz.vue'),
        },
        {
          path: 'contract',
          name: 'Contract',
          component: () => import('@/views/sign-up/Contract.vue'),
          beforeEnter: requireAuth,
        },
        {
          path: 'bed-selection/:destination/:inviteFriend',
          name: 'Bed Selection',
          component: () => import('@/views/sign-up/BedSelection.vue'),
          beforeEnter: requireAuth,
        },
        {
          path: 'add-pmt-method',
          name: 'Add Payment Method',
          component: () => import('@/views/sign-up/AddPmtMethod.vue'),
          beforeEnter: requireAuth,
        },
        {
          path: 'make-pmt',
          name: 'Make Payment',
          component: () => import('@/views/sign-up/MakePmt.vue'),
          beforeEnter: requireAuth,
        },
        {
          path: 'feedback',
          name: 'Feedback',
          component: () => import('@/views/sign-up/Feedback.vue'),
          beforeEnter: requireAuth,
        },
        {
          path: 'renew',
          name: 'Renew',
          component: () => import('@/views/sign-up/Renew.vue'),
          beforeEnter: requireAuth,
        },
        {
          path: 'add-friend-request/:added',
          name: 'Add Friend Request',
          component: () => import('@/views/sign-up/AddFriendRequest.vue'),
          beforeEnter: requireAuth,
        },
        {
          path: '/changePeriod/:propertyID',
          name: 'changePeriodModal',
          component: () => import('@/components/ChangePeriodModal.vue'),
          beforeEnter: requireAuth,
        },
        {
          path: '/changeProperty',
          name: 'changePropertyModal',
          component: () => import('@/components/ChangePropertyModal.vue'),
          beforeEnter: requireAuth,
        },
      ],
    },
    {
      path: '/logout',
      beforeEnter (to, from, next) {
        // TODO
        throw new Error('Logout doesn\'t work')
        // auth.logout();
        next('/')
      },
      redirect: '/'
    },
  ]}],
})
