// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { MutationPayload } from 'vuex'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import axios from 'axios'
import { vueform } from '@vueform/vueform'
import './plugins/filters'
import { formatDate, formatPhone } from './plugins/filters'
import { currency } from './plugins/currency'
import * as Sentry from '@sentry/vue'
import { vOnClickOutside } from '@vueuse/components'
import vueformConfig from './../vueform.config'

const app = createApp(App as any).use(store)
// Vue configs
app.config.performance = true

app.use(vueform, vueformConfig)
app.use(router)

// Global functions
app.config.globalProperties.$formatDate = formatDate
app.config.globalProperties.$formatPhone = formatPhone

app.config.globalProperties.$currency = currency

// Directives
app.directive( 'click-outside', vOnClickOutside)

// Sentry Integrations
Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.replayIntegration(),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});



// TODO Ideally we want to use axios instance.
// START of axios config.
;(window as any).axios = axios
if (process.env.VUE_APP_API_BASE_URL) {
  (window as any).axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
} else {
  (window as any).axios.defaults.baseURL = `https://${window.location.hostname}/`
}

const urlParams = new URLSearchParams(window.location.search)
if (urlParams.get("token")) {
  localStorage.setItem('token', urlParams.get('token'))
  localStorage.setItem('user', urlParams.get('user'))
  localStorage.setItem('organizationID', urlParams.get('organizationID'))
  localStorage.setItem('propOrganizationID', urlParams.get('organizationID'))

  window.location.replace(location.pathname);
}

if (localStorage.getItem('token') !== null) {
  (window as any).axios.defaults.headers.common.authorization = 'Token ' + localStorage.token
} else if (localStorage.getItem('guarantorToken') !== null) {
  (window as any).axios.defaults.headers.common.authorization = 'Token ' + localStorage.guarantorToken
}

if (localStorage.getItem('organizationID') !== null) {
  (window as any).axios.defaults.headers.common['org-id'] = localStorage.organizationID
}

axios.interceptors.response.use(null, function(error: any) {
  if (error.response && error.response.status === 401) {
    localStorage.clear()
    if (store.state.activeOrganization.default_domain) {
      window.location.href = store.state.activeOrganization.default_domain
    } else {
    window.location.href = process.env.VUE_APP_ROOMCHOICE_APP_URL
    }
  }
  return Promise.reject(error)
});

// TODO Not sure what is this for.
store.subscribe((mutation: MutationPayload, state: Object | Function) => {
  // Updating axios header org-id when its value changes
  if (mutation.type === 'setActiveOrganizationId') {
    (window as any).axios.defaults.headers.common['org-id'] = localStorage.organizationID
  }
})

// END of axios config.


/* eslint-disable no-new */
app.mount('#app')
export default app
