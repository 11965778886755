const createQueryParamsString = (params: any) => Object.keys(params).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&')

export const API = {

  ACCOUNTS: {
    BASE: '/api/accounts/',
    SINGLE: (accountId: number) => `/api/accounts/${accountId}/`,

    CONFIRM: (accountId: number) => `/api/accounts/${accountId}/confirm/`,
    CREATE_PLAID_LINK: `/api/accounts/create_plaid_link/`
  },

  AGREEMENT_PERIODS: '/api/agreement-periods/',
  ALL_AGREEMENT_PERIODS: 'api/agreement-periods/?show_all=true',

  CARDS: {
    BASE: '/api/cards/',
    SINGLE: (cardId: number) => `/api/cards/${cardId}/`,
  },

  CONTRACT: {
    GUARANTOR_STEP: (inviteId: number) => `/api/contract/guarantor-step/?invite=${inviteId}`,
    SALES_FUNNEL: (reservationId: number) => `/api/contract/?sales_funnel=${reservationId}`,
  },

  DIRECT_SET_PASSWORD: (userEmail: string) => `/api/direct-set-password/${userEmail}/`,
  DIRECT_SET_EMAIL: '/api/direct-set-email/',

  EMAIL_SET_PASSWORD: {
    BASE: '/api/email-set-password/',
    WITH_INVITE_ID: (inviteId: number) => `/api/email-set-password/?invite_id=${inviteId}`,
  },

  INVITATIONS: {
    CLAIM: (token: string) => `/api/invitations/claim/?token=${token}`,
    CONFIRM: (token: string) => `/api/invitations/confirm/?token=${token}`,
    DECLINE: (token: string) => `/api/invitations/decline/?token=${token}`,
  },

  MESSAGES: {
    TENAT_TO_TENAT: '/api/messages/tenant_to_tenant/',
    TO_PROPERTY: '/api/messages/to_property/',
  },

  ORGANIZATIONS: {
    BASE: '/api/organizations/',
    SINGLE: (organizationId: number) => `/api/organizations/${organizationId}/`
  },

  PAYMENT_SCHEDULES: {
    BASE: '/api/payment-schedules/',
    SET_DELETED: (scheduleID: number) => `/api/payment-schedules/${scheduleID}/set_deleted/`,
  },

  PERSONALITY_QUIZ: {
    GET_OR_CREATE_USER_ASSESSMENT: '/api/personality-quiz-service/get-or-create-user-assessment/',
    COMPLETE_QUIZ: '/api/personality-quiz-service/complete-quiz/',
    SKIP_QUIZ: '/api/personality-quiz-service/skip-quiz/',
  },

  PORTFOLIOS: '/api/portfolios/',

  PROPERTIES: {
    BASE: '/api/properties/',
    SINGLE: (propertyId: number) => `/api/properties/${propertyId}/`,

    CONTACT: (propertyId: number) => `/api/properties/${propertyId}/contact/`,
  },

  PROPERTIES_SETTINGS: (propertyId: number) => `api/properties-settings/${propertyId}/`,
  PROPERTIES_SITE: (propertyId: number) => `/api/properties-site/${propertyId}/`,
  PROPERTY_SETTINGS_RESOURCE: (propertyId: number) => `api/property-settings-resource/${propertyId}/`,

  REMIND_LEAD: '/api/remind-lead/',
  REMIND_USER: (userId: number, token: string) => `/api/remind-user/?user_id=${userId}&token=${token}`,

  REPORT: {
    CONFIRM_SERVICES: '/api/report/confirm_services/',
    NEW_REALTY: '/api/report/new_realty/',
    NEW_SERVICES: '/api/report/new_services/',
  },

  RESOURCES: {
    BASE: '/api/resources/',
    BELONGS_TO: (userId: number, propertyId: number) => `/api/resources/?belongs_to=${userId}&property=${propertyId}`,
  },

  SALES_FUNNELS: {
    ADD_FRIEND: (funnelId: number) => `/api/sales-funnels/${funnelId}/add_friend/`,
    ADD_CHARGE: (funnelId: number, chargeOptionId: number) => `/api/sales-funnels/${funnelId}/add_charge/?id=${chargeOptionId}`,
    APP: (funnelId: number) => `/api/sales-funnels/${funnelId}/app/`,
    CANCEL: (funnelId: number) => `/api/sales-funnels/${funnelId}/cancel/`,
    CONFIRM: (funnelId: number) => `/api/sales-funnels/${funnelId}/confirm/`,
    FETCH: (funnelId: number) => `/api/sales-funnels/${funnelId}/fetch/`,
    FILL_FORM: (funnelId: number) => `/api/sales-funnels/${funnelId}/fill_form/`,
    GET_GUARANTOR_FROM_INVITE: (inviteId: number) => `/api/sales-funnels/get_guarantor_from_invite/?invite=${inviteId}`,
    PAY_FIRST_NOW: {
      BASE: (funnelId: number) => `/api/sales-funnels/${funnelId}/pay_first_now/`,
      WITH_SIDEBAR: (funnelId: number) => `/api/sales-funnels/${funnelId}/pay_first_now/?sidebar=true`,
    },
    VUE_CHARGES: (funnelId: number) => `/api/sales-funnels/${funnelId}/vue_charges/`,
    MATCH_SCORE: (funnelId: number, queries: { floor: number, tenant: number, info: boolean }) => `/api/sales-funnels/${funnelId}/match_score?${createQueryParamsString(queries)}`
  },

  SEND_RESET_EMAIL: '/api/send_reset_email/',

  SETTINGS_RESOURCE: '/api/settings-resource/',

  TICKETS: {
    CATEGORIES: '/api/tickets/categories/',
  },

  TOKEN_AUTH: '/api-token-auth/',

  USER_EMAIL_CONFIRMATION: '/api/user-email-confirmations/',

  USERS: {
    SINGLE: (userId: number) => `/api/users/${userId}/`,

    APP: (userId: number, propertyId: number) => `/api/users/${userId}/app/?property=${propertyId}`,
    AGREED_TO_TOS: (userId: number) => `/api/users/${userId}/agreed_to_tos/`,
    APP_APPLICATION: (userId: number, propertyId: number) => `/api/users/${userId}/app/?fields=application&property=${propertyId}`,
    APP_PERSONAL: (userId: number, propertyId: number) => `/api/users/${userId}/app/?fields=personal&property=${propertyId}`,
    ESTIMATE_FEES: (userId: number) => `/api/users/${userId}/estimate_fees/`,
    FILL_FORM: (userId: number, propertyId: number) => `/api/users/${userId}/fill_form/?property=${propertyId}`,
    GET_ACCOUNTS: (userId: number) => `/api/users/${userId}/get_accounts/`,
    GET_CARDS: (userId: number) => `/api/users/${userId}/get_cards/`,
    GET_RELATED_USERS: (userId: number) => `/api/users/${userId}/get_related_users/`,
    INBOX: (userId: number, queries: { property: number }) => `/api/users/${userId}/inbox/?${createQueryParamsString(queries)}`,
    INVITATIONS: (userId: number, queries: { property: number }) => `/api/users/${userId}/invitations/?${createQueryParamsString(queries)}`,
    LEDGERS: (userId: number, queries: { property: number }) => `/api/users/${userId}/ledgers/?${createQueryParamsString(queries)}`,
    ME: (queries: { property: number }) => `/api/users/me/?${createQueryParamsString(queries)}`,
    MY_TICKETS: (userId: number, queries: { property: number }) => `/api/users/${userId}/my_tickets/?${createQueryParamsString(queries)}`,
    NEW_RESERVATION: (userId: number) => `/api/users/${userId}/new_reservation/`,
    NEW_TICKET: (userId: number) => `/api/users/${userId}/new_ticket/`,
    SENT_MESSAGES: (userId: number, queries: { property: number }) => `/api/users/${userId}/sent_messages/?${createQueryParamsString(queries)}`,
    SHOW_PUBLIC_PROFILE: (userId: number, showValue: boolean) => `/api/users/${userId}/show_public_profile/?set=${showValue}`,
    SIGN_UP: '/api/users/sign_up/',
    TENAT_PAYMENT: (userId: number) => `/api/users/${userId}/tenant_payment/`,
    UNITS: (userId: number) => `/api/users/${userId}/units/`,
  },

  VUE_SALES_FUNNEL: {
    UPDATE_CONTRACT: (funnelId: number) => `/api/vue-sales-funnel/${funnelId}/update_contract/`,
    UPDATE_QUIZ: (funnelId: number) => `/api/vue-sales-funnel/${funnelId}/update_quiz/`,
    UPDATE_PAYMENT: (funnelId: number) => `/api/vue-sales-funnel/${funnelId}/update_payment/`,
  },

  VUE_USER_SALES_FUNNELS: {
    GET_SALES_FUNNELS: (userId: number, queries: { property: number, showFriendsTab: boolean }) =>
      `/api/vue-user-sales-funnels/${userId}/get_sales_funnels/?${createQueryParamsString(
        queries
      )}`,
    GET_SALES_FUNNELS_COUNT: (
      userId: number,
      queries: { property: number; showFriendsTab: boolean }
    ) =>
      `/api/vue-user-sales-funnels/${userId}/get_sales_funnels_count/?${createQueryParamsString(
        queries
      )}`,
  },
}
