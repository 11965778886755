export const sortedReservations = (state: any) => {
  var reservations = state.userReservations
  var today = new Date()
  var endOfPeriod: '' | Date = ''
  var pastReservations = []
  var activeReservations = []
  var pendingReservations = []
  var renewingReservations = []

  for (var i = 0; i < reservations.length; i++) {
    endOfPeriod = new Date(reservations[i].agreement_period_end_date)
    if (reservations[i].is_approved && endOfPeriod < today) {
      pastReservations.push(reservations[i])
    } else if (reservations[i].is_approved) {
      activeReservations.push(reservations[i])
    } else if (reservations[i].same_bed_renewal) {
      renewingReservations.push(reservations[i])
    } else {
      pendingReservations.push(reservations[i])
    }
  }
  return {
    pastReservations: pastReservations,
    activeReservations: activeReservations,
    pendingReservations: pendingReservations,
    renewingReservations: renewingReservations,
  }
}
export const activePeriods = (state: any) => {
  var periods = state.periods
  var activePeriods = []
  var inactivePeriods = []

  for (var i = 0; i < periods.length; i++) {
    if (periods[i].visible === true) {
      activePeriods.push(periods[i])
    } else {
      inactivePeriods.push(periods[i])
    }
  }
  if (activePeriods.length >= 1) {
    activePeriods = activePeriods.sort(function (a, b) {
      var startA = a.start_date
      var startB = b.start_date
      if (startA < startB) {
        return -1
      }
      if (startA > startB) {
        return 1
      }
      return 0
    })
  }
  return {
    activePeriods: activePeriods,
    inactivePeriods: inactivePeriods,
  }
}
